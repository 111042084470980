.form-demo .card {
    min-width: 450px;
}
.form-demo .card form {
    margin-top: 3rem;
    margin: 20PX;
}
.form-demo .card .field {
    margin-bottom: 1.5rem;
}
.form-demo .card .text_heading{
    margin-top: 1rem;
    font-size: 24px;
}
.form-demo .card .Avatar{
    margin-top: 5rem;
    margin-left: 17rem;
}
@media screen and (max-width: 960px) {
    .form-demo .card {
        width: 80%;
    }
    .form-demo .card .Avatar{
        margin-top: 6rem;
        margin-left: 17rem;
    }
}
@media screen and (max-width: 640px) {
    .form-demo .card {
        width: 100%;
        min-width: 0;
    }
    .form-demo .card .Avatar{
        margin-top: 5rem;
        margin-left: 14rem;
    }
}